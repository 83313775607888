
import $ from 'jquery';
window.$ = window.jQuery = $;

require('./theme/circularprogress.js');
// require('./theme/glitch.js');
require('./theme/isotope.js');
require('./theme/lettertype.js');
require('./theme/magnificpopup.js');
require('./theme/nicescroll.js');
require('./theme/owlcarousel.js');
require('./theme/particle.js');
require('./theme/ripple.js');
require('./theme/waypoints.js');
require('./theme/init.js');